import classNames from 'classnames';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function SquareMediaText({ children, media, left, className }) {
  const imageData = getImage(media);

  return (
    <div className={classNames(['flex flex-col lg:flex-row', className])}>
      <div
        className={classNames([
          'lg:aspect-square flex-1 basis-1/2 lg:relative w-full lg:w-auto',
          { 'order-last': left },
        ])}
      >
        <GatsbyImage
          className="w-full lg:absolute lg:inset-0"
          image={imageData}
          objectPosition="cover"
          role="presentation"
          alt=""
        />
      </div>

      <div className="bg-white lg:aspect-square flex-1 basis-1/2 p-4 sm:p-8 md:p-12 lg:p-16 xl:p-24 2xl:p-32 flex justify-start items-center">
        <div>{children}</div>
      </div>
    </div>
  );
}
