import { graphql } from 'gatsby';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import CTA from '../components/CTA';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import SquareMediaText from '../components/SquareMediaText';
import defaultPicture from '../images/PellerinGuitares_140.jpeg';

const translations = [
  {
    locale: 'fr',
    url: '/a-propos/',
  },
];

export default function AboutPage({ data }) {
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          About
          <br />
          Pellerin Guitars
        </PageHeader>

        <section className="container">
          <div className="">
            <Heading level={2}>
              The journey of a guitar maker: <br />
              25&nbsp;years of sound and passion
            </Heading>

            <div className="lg:columns-2 space-y-4">
              <p>
                After training as a guitar maker, Michel Pellerin worked for a
                short period of time in a guitar factory. He quickly came to
                realize that assembly-line work was not his cup of tea; he would
                rather devote his body, soul and energy to creating unique
                instruments of his own design.
              </p>

              <p>
                When he began doing guitar repairs, he could see the mistakes
                that some companies were making. His innate attention to detail
                and desire to create outstanding guitars have remained his
                driving motivational force.
              </p>

              <p>
                Pellerin has a unique ability to hear the vibrations of the wood
                even before the guitar is finished. For a few years, he passed
                this expertise on to students at Quebec National Lutherie School
                in his repair and finishing and varnishing classes. Some of the
                finest international makers also call upon his services for
                private lessons.
              </p>

              <p>
                Pellerin’s quest for absolute perfection never ends. Through
                sharing his expertise, he never ceases to think and rethink his
                methods, striving to constantly improve his craft.
              </p>

              <p>
                To date, Michel Pellerin and his work partner, François
                C.Paradis, have made and pushed the envelope of over 250
                exceptional guitars.
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <SquareMediaText
            media={data.reputationImage}
            className="wide-content-contained"
          >
            <Heading level={2} className="!mt-0">
              A reputation that echoes worldwide
            </Heading>

            <p>
              World-class guitar makers like Michel Pellerin are rare. That’s
              why even the greatest makers call upon his expertise for private
              lessons. All of them recognize Pellerin’s methodical approach of
              writing down all the distinctive characteristics of each guitar to
              ensure a continued improvement in his work. Pellerin has grasped
              the concept of energy transfer and the connection between the
              various parts of a guitar. He is a true virtuoso, always seeking
              the best results.
            </p>
          </SquareMediaText>

          <SquareMediaText
            media={data.playImage}
            className="wide-content-contained"
            left
          >
            <Heading level={2} className="!mt-0">
              With full-on quality, play full on
            </Heading>

            <p>
              Michel Pellerin has a solid understanding of living and organic
              materials. With his utmost skill, he is able to create instruments
              that stand the test of time. His technique is set like a
              metronome: there is no room for error. The design, the materials,
              the adjustments and the resonance must come together in a
              symphony. Michel Pellerin demands nothing less than the perfect
              note in perfect harmony.
            </p>
          </SquareMediaText>

          <SquareMediaText
            media={data.workImage}
            src={defaultPicture}
            className="wide-content-contained"
          >
            <Heading level={2} className="!mt-0">
              Artisans at work
            </Heading>

            <p>
              Here are Michel Pellerin and François C.Paradis in the workshop in
              full work of creation. Surrounded by wood species and instruments,
              Michel and François are in perfect harmony with their passion.
              Inspired and focused, they let all their musicality go to create
              guitars that transform the lives of musicians.
            </p>
          </SquareMediaText>
        </section>

        <section>
          <CTA>
            <Heading level={2} className="!mt-0">
              Guitars that rock
            </Heading>

            <p className="max-w-prose my-4">
              Over the years, Michel Pellerin and his business partner François
              C.Paradis have created more than 250 distinctive guitars — or
              “works of art”, which may be a more appropriate term. Here is a
              quick look at some of the most beautiful Pellerin guitars.
            </p>

            <UnderlinedButton to="/en/creations" className="text-xl my-5">
              Check out the guitars
            </UnderlinedButton>
          </CTA>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    reputationImage: file(name: { eq: "PellerinGuitares_122" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768)
      }
    }
    playImage: file(name: { eq: "PellerinGuitares_169" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          height: 768
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    workImage: file(name: { eq: "PellerinGuitares_150" }) {
      childImageSharp {
        gatsbyImageData(
          width: 768
          height: 768
          transformOptions: { cropFocus: EAST }
        )
      }
    }
  }
`;
